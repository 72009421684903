import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
const slugify = require(`slug`)



class TagsList extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const group = data.allMdx.group

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <div>
          <div>
            <h2>Tags</h2>
            <ul>
              {group.map(tag => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${slugify(tag.fieldValue)}/`}>
                    {tag.fieldValue}  - {tag.totalCount}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TagsList
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      filter: {frontmatter: {published: {ne: false}}}
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
